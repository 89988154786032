<template>
  <v-container fluid>
    <div class="d-flex mb-3" v-if="all">
      <v-alert type="info" class="ma-0" dense text> Eventos pasados </v-alert>
    </div>

    <div class="d-flex mb-2">
      <v-btn small color="primary" @click.stop="getEventos">
        <v-icon left small>mdi-refresh</v-icon>
        Recargar</v-btn>
    </div>

    <v-card>
      <v-tabs v-model="tipo">
        <v-tab href="#">TODOS</v-tab>
        <v-tab
          v-for="tipo in tipos"
          :key="tipo.idTipoEvento"
          :href="`#${tipo.idTipoEvento}`"
          >{{ tipo.tipo }}</v-tab
        >
      </v-tabs>

      <v-data-table
        fixed-header
        :items="eventos"
        :headers="eventosHeaders"
        class="fixed-checkbox"
        item-key="idEvento"
        :single-select="false"
        show-select
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
            itemsPerPageOptions: [10, 25, 50, -1],
        }"
        checkbox-color="secondary"
        @contextmenu:row="contextMenuEvento"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="eventos"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.tipoEvento="{ item }">
          <v-chip
            class="v-chip--active font-weight-bold"
            outlined
            label
            :color="item.colorTipoEvento"
          >
            <v-icon left>{{ item.iconTipoEvento }}</v-icon>
            {{ item.tipoEvento }}
          </v-chip>
        </template>

        <template v-slot:item.estado="{ item }">
          <v-chip
            class="v-chip--active font-weight-bold"
            outlined
            label
            :color="item.colorEstadoEvento"
            @click.stop="cambioEstado = item.idEvento"
          >
            <v-icon left>{{ item.iconEstadoEvento }}</v-icon>
            {{ item.estado }}
          </v-chip>
        </template>

        <template v-slot:item.acciones="{ item }">
          <div class="d-flex align-center">
            <v-btn
              :disabled="!item.editable"
              rounded
              color="primary"
              :to="`/eventos/${item.idEvento}`"
            >
              <v-icon left>mdi-pencil</v-icon>
              Editar
            </v-btn>
            <!-- <v-btn
              :disabled="item.estado != 'Terminado'"
              rounded
              color="primary"
							class="ml-1"
              :to="`/eventos/${item.idEvento}/informe`"
							v-if="$root.acceso('CREAR_INFORME')"
            >
              <v-icon left>mdi-file-sign</v-icon>
              Generar informe
            </v-btn> -->
          </div>
        </template>

        <template v-slot:item.tipoBoda="{ item }">
          <v-chip
            class="v-chip--active"
            outlined
            label
            :color="item.colorTipoBoda"
            v-if="item.tipoBoda"
          >
            {{ item.tipoBoda }}
          </v-chip>
        </template>

        <template v-slot:item.datos.tipoCelebracion="{ item }">
          <v-chip
            class="v-chip--active"
            outlined
            label
            v-if="item.datos.tipoCelebracion"
          >
            <v-icon left>mdi-party-popper</v-icon>
            {{ item.datos.tipoCelebracion }}
          </v-chip>
        </template>

        <template v-slot:item.nombreEspacio="{ item }">
          <v-chip
            class="v-chip--active"
            outlined
            label
            v-if="item.nombreEspacio"
          >
            <v-icon left>mdi-map-marker</v-icon>
            {{ item.nombreEspacio }}
          </v-chip>
        </template>

        <template v-slot:item.nombreSalon="{ item }">
          <div v-if="item.nombreSalon" class="d-flex" style="gap: 3px">
            <v-chip
              class="v-chip--active"
              outlined
              label
              v-for="sal in item.nombreSalon.split(',')"
              :key="sal"
            >
              <v-icon left>mdi-map-marker-outline</v-icon>
              {{ sal }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.nombreVia="{ item }">
          <v-chip v-if="item.nombreVia" outlined label small>
            {{ item.nombreVia }}
          </v-chip>
        </template>

        <template v-slot:item.nAdultos="{ item }">
          <v-chip small v-if="item.nAdultos > 0" label>
            <v-icon left small>mdi-human-male</v-icon>
            {{ item.nAdultos }}
          </v-chip>
          <v-icon small v-else>mdi-minus</v-icon>
        </template>

        <template v-slot:item.nNinos="{ item }">
          <v-chip small v-if="item.nNinos > 0" label>
            <v-icon left small>mdi-human-child</v-icon>
            {{ item.nNinos }}
          </v-chip>
          <v-icon small v-else>mdi-minus</v-icon>
        </template>

        <template v-slot:item.tcos="{ item }">
          <v-chip small v-if="item.tcos > 0" label>
            <v-icon left small>mdi-human-male-female-child</v-icon>
            {{ item.tcos }}
          </v-chip>
          <v-icon small v-else>mdi-minus</v-icon>
        </template>

        <template v-slot:item.enlaceCivil="{ item }">
          <v-icon
            small
            v-if="item.enlaceCivil !== undefined && !item.enlaceCivil"
            color="error"
            >mdi-close-circle-outline</v-icon
          >
          <v-icon
            small
            v-else-if="item.enlaceCivil !== undefined && item.enlaceCivil"
            color="success"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
        </template>

        <template v-slot:item.datos.fechaInicioEvento="{ item }">
          <v-chip>
            <v-icon left>mdi-calendar</v-icon>
            <span v-text="parseDate(item.datos.fechaInicioEvento)"></span>
          </v-chip>
        </template>

        <template v-slot:item.fechaReserva="{ item }">
          <span v-text="parseDate(item.fechaReserva, false, true, true)"></span>
        </template>
      </v-data-table>
    </v-card>

    <div class="d-flex mt-2">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        fab
        fixed
        bottom
        right
        v-if="$root.acceso('CREAR_EVENTO')"
        to="/eventos/nuevo"
        large
      >
        <v-icon>mdi-plus</v-icon>
        <!-- Añadir evento -->
      </v-btn>
    </div>

    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>{{ detailsId }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="detailsId = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <detalles-evento :key="detailsId" :idEvento="detailsId"></detalles-evento>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      :value="cambioEstado"
      @input="(v) => (v === false ? (cambioEstado = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>Cambiar estado {{ cambioEstado }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="cambioEstado = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <cambio-estado
        @close="cambioEstado = null"
        @reload="getEventos"
        :key="cambioEstado"
        :idEvento="cambioEstado"
      ></cambio-estado>
    </v-dialog>

    <component :is="contextMenuComp" />
  </v-container>
</template>

<script>
import contextMenu from "../services/contextMenu.js";
import { parseDate, perColumnFilter } from "@/utils";

export default {
  components: {
    CambioEstado: () => import("../components/CambiarEstado.vue"),
    DetallesEvento: () => import("./Evento.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: {
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inlineFilters: { idEvento: this.$route.query.filter ? `=${this.$route.query.filter}` : null },
      tipo: null,
      eventos: [],
      loading: false,
      contextMenuComp: null,
      detailsId: null,
      cambioEstado: null,
      tipos: [],
    };
  },
  computed: {
    eventosHeaders() {
      return [
        { text: "Cód.", value: "idEvento" },
        { text: "Tipo", value: "tipoEvento", dataType: "select" },
        { text: "Estado", value: "estado", dataType: "select" },
        {
          text: "Acciones",
          value: "acciones",
          filterable: false,
          sortable: false,
        },
        {
          text: "F.Inicio",
          value: "datos.fechaInicioEvento",
          dataType: "date",
        },
        { text: "Horario", value: "nombreHorario", dataType: "select" },

        {
          text: "Novios",
          value: "datos.novios",
          cellClass: "pa-1 text-no-wrap font-weight-bold",
          tab: 1,
        },
        {
          text: "Niño",
          value: "datos.nino",
          cellClass: "pa-1 text-no-wrap font-weight-bold",
          tab: 2,
        },
        {
          text: "Padres",
          value: "datos.padres",
          cellClass: "pa-1 text-no-wrap font-weight-bold",
          tab: 2,
        },

        { text: "Breve Dato", value: "datos.breveDato" },

        { text: "Nombre completo", value: "cliente.nombreCompleto" },

        { text: "Espacio", value: "nombreEspacio", dataType: "select" },
        { text: "Salon", value: "nombreSalon", dataType: "select" },
        { text: "Tipo boda", value: "tipoBoda", dataType: "select", tab: 1 },
        {
          text: "Tipo Celebracion",
          value: "datos.tipoCelebracion",
          dataType: "select",
          tab: [3, 4],
        },
        {
          text: "E.C",
          value: "enlaceCivil",
          dataType: "bool",
          align: "center",
          tab: 1,
        },
        // {
        //   text: "CRIT",
        //   value: "fechaCritica",
        //   dataType: "bool",
        //   align: "center",
        // },
        { text: "Adultos", value: "datos.nAdultos" },
        { text: "Niños", value: "datos.nNinos" },
        { text: "Tcos.", value: "datos.tcos" },

        { text: "Via rva", value: "nombreVia", dataType: "select" },
        { text: "Procedencia", value: "cliente.procedencia" },

        { text: "Teléfono", value: "cliente.telefono" },
        { text: "Email", value: "cliente.email" },

        { text: "Reserva", value: "fechaReserva", dataType: "date" },
        { text: "Apuntado por", value: "responsable" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso)) &&
            (h.tab == null ||
              [h.tab].flat().includes(this.tipo * 1) ||
              !this.tipo)
        )
        .map((header) => ({
          class: "text-no-wrap sticky-header",
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  watch: {
    tipo(n) {
      this.getEventos(n);
    },
  },
  mounted() {
    // this.getEventos();
    this.getTiposEvento();
  },
  methods: {
    async getTiposEvento() {
      const { data } = await axios("/opciones/tipoEventos");
      this.tipos = data;
    },
    contextMenuEvento(event, item) {
      contextMenu(event, item.item, this, item.isSelected, item.select);
    },
    parseDate,
    async getEventos() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: "eventos/",
        params: { tipo: this.tipo || null, all: this.all },
      });

      this.eventos = data.map((m) => {
        m.datos = JSON.parse(m.datos);
        m.cliente = m.datos.clientes[0];
        m.datos.tcos = (m.datos.nNinos * 1 || 0) + (m.datos.nAdultos * 1 || 0);
        return m;
      });
      this.loading = false;
    },
  },
};
</script>
