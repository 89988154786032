/* eslint-disable no-unused-vars */
import Vue from "vue";
import {
  VMenu,
  VList,
  VListItem,
  VListItemGroup,
  VListItemContent,
  VListItemIcon,
  VListItemTitle,
  VIcon,
  VBtn,
  VDivider,
  VListItemSubtitle,
  VListItemAction,
} from "vuetify/lib";

export default function(event, item, instance, isSelected, select) {
  event.preventDefault();

  const actions = [
    {
      actions: [
        {
          text: "Editar",
          icon: "mdi-pencil",
          handler: () => {
						instance.$router.push(`/eventos/${item.idEvento}`)
					},
					disabled: !item.editable
        },
        {
          text: isSelected ? "Deseleccionar" : "Seleccionar",
          icon: !isSelected
            ? "mdi-checkbox-blank-outline"
            : "mdi-minus-box-outline",
          handler: () => select(!isSelected),
        },
      ],
    },
    {
      actions: [
        {
          text: "Recargar",
          icon: "mdi-refresh",
          handler: () => instance.getEventos(),
        },
      ],
    },
  ];

  const menuComp = Vue.component("context-menu", {
    render: function(h) {
      return h(
        VMenu,
        {
          props: {
            value: true,
            dense: true,
            positionX: event.pageX,
            positionY: event.pageY,
            position: "absolute",
            transition: "slide-y-transition",
            minWidth: "300",
          },
        },
        [
          h(
            VList,
            {
              props: { nav: true },
              style: `border-top: 5px solid ${item.colorTipoEvento}`,
            },
            [
              actions.map((action) =>
                h(VListItemGroup, {}, [
                  ...action.actions.map((a) =>
                    h(
                      VListItem,
                      { props: { dense: true, disabled: a.disabled }, on: { click: a.handler } },
                      [
                        h(VListItemIcon, {}, [h(VIcon, a.icon)]),
                        h(VListItemContent, {}, [
                          h(VListItemTitle, {}, [a.text]),
                          h(VListItemSubtitle, {}, [a.subText]),
                        ]),
                        a.actionIcon
                          ? h(VListItemAction, {}, [
                              h(
                                VBtn,
                                {
                                  props: { icon: true, small: true },
                                  on: { click: a.handlerAction },
                                },
                                [h(VIcon, { props: {small: true} }, a.actionIcon)]
                              ),
                            ])
                          : null,
                      ]
                    )
                  ),
                  h(VDivider, {class: 'mb-1'})
                ])
              ),
            ]
          ),
        ]
      );
    },
  });

  instance.contextMenuComp = menuComp;
}
